import React, { useState, useEffect, useRef } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

import blogStyles from '../sass/pages/blog.module.scss'
import bodyStyles from '../sass/components/body.module.scss'
import commonStyles from '../sass/components/common.module.scss'
import pageStyles from '../sass/pages/page.module.scss'
import umbrellaIconSvg from '../assets/icons/vcd-umbrella-color.svg'
import bloggerIconSvg from '../assets/icons/vcd-blogger-color.svg'
import swimmingPoolIconSvg from '../assets/icons/vcd-swimming-pool-color.svg'
import mountainIconSvg from '../assets/icons/vcd-mountain-color.svg'

import {
  umbrellaIconAlt,
  bloggerIconAlt,
  swimmingPoolIconAlt,
  mountainIconAlt,
} from '../constants/iconAlt'

const cmS = commonStyles
const bgS = blogStyles
const bS = bodyStyles

// let cl = (...arg) => console.log(...arg)

// Build new custom hook "useInterval"
function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

// React Page
const PageList = () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        pages {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    }
  `)

  function SlidingWord() {
    const [wordIndex, setWordIndex] = useState(0)
    useInterval(() => {
      setWordIndex((wordIndex + 1) % slidingWord.length)
      // setWordIndex(Math.floor(Math.random() * slidingWord.length))
    }, 2000)
    return slidingWord[wordIndex]
  }

  const slidingWord = [
    'petits sentiers 🌿',
    'artisans valeureux 👩‍🎨',
    'curiosités locales 👀',
    'coins secrets 🐸',
    'crépuscules fascinants 🌄',
    'cascades féériques 🌊',
    'points sublimes 🦅',
    'villages authentiques ⭐',
    'marchés du pays 👨‍🌾',
    'petites sources 💧',
    'randonnée époustouflantes 🏞️',
    'nuits étoilées 🌌',
    'activités sportives 🧗',
    '...',
  ]

  return (
    <Layout>
      <Head title={'Blog'} />
      <div className={bS.container}>
        <h1 className={cmS.title}> Le Blog de Gérard </h1>
        <div className={cmS.iconWrapperWrapper}>
          <div className={cmS.iconWrapper}>
            <img
              className={cmS.box1Icon}
              src={swimmingPoolIconSvg}
              alt={swimmingPoolIconAlt}
            />
            <img
              className={cmS.box1Icon}
              src={umbrellaIconSvg}
              alt={umbrellaIconAlt}
            />
            <img
              className={cmS.box2Icon}
              src={bloggerIconSvg}
              alt={bloggerIconAlt}
            />
            <img
              className={cmS.box2Icon}
              src={mountainIconSvg}
              alt={mountainIconAlt}
            />
          </div>
        </div>

        <div className={cmS.mainComponentWrapper}>
          <h1 className={cmS.subtitle}>
          <div className={bgS.slidingWordWrapper}>
          Mille et un(e) 
            <div className={bgS.slidingWord}>
            <SlidingWord />
            </div>
            <div className={bgS.slidingWordSideElemeent}></div>
          </div>
  
          </h1>
          <div className={bgS.paragraphWrapper}>
            <div className={bgS.paragraphBox}>
              <div className={cmS.paragraph}>
                <p>
                  A venir, notre blog, où il sera question de Provence, coins
                  secrets et activités insolites à découvrir, et produits du
                  terroir, mais aussi de randonnées et voyages.
                  <br />
                  <br />
                  En plus d'être loueur-gérant aux petits soins pour ses
                  vacanciers, on peut aussi être de très bon conseil pour les
                  mille et une activités possibles dans le pittoresque arrière
                  pays provençal, entre Gorges du Verdon et Côte d'Azûr...
                </p>
              </div>
            </div>
            <div className={bgS.paragraphBox}>
              <div className={cmS.paragraph}>
                <p>
                  Vous découvrirez ici, virtuellement et en avant-goût de cet
                  été, la Provence, avec un jeune vieux (ou vieux jeune)
                  voyageur, randonneur et baroudeur qui aime aller toujours plus
                  loin, apprendre d'autres cultures et d'autres terres.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*
        <ol className={pageStyles.pages}>
          {data.wpgraphql.pages.edges.map(page => (
            <li className={pageStyles.page} key={page.node.slug}>
              <Link to={`/page/${page.node.slug}`}>
                <h3>{page.node.title}</h3>
                <p>{page.node.slug}</p>
              </Link>
            </li>
          ))}
        </ol>
          */}
          
      </div>
    </Layout>
  )
}

export default PageList

// Il se passionne aujourd'hui d'itinéraires sur les îles Canaries, desquelles il peut parler des heures durant - son île préférée est El Hierro, la plus verte, reculée et préservée (ou Fuerteventura, il hésite) même s'il affectionne particulièrement Gran Canaria !
// <br/><br/>
// Il y a peu, il s'est épris du Costa Rica... et s'y est rendu : au programme, une exploration en mode "aventurier", si si !
// En ce moment, soit il prépare secrètement d'autres voyages et randonnées... soit il s'attèle déjà à cultiver les fleurs qui orneront votre location cet été !
